



































import ICrudClient from "@/lib/ICrudClient";
import { Activity } from "@/data/models/Activity";
import DataProvider from "@/lib/DataProvider";
import EntitySelect from "@/components/EntitySelect.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import EnumForm from "@/components/forms/EnumForm.vue";
import { ActivityType } from "@/data/models/ActivityType";

@Component({
  components: {
    FormContainer,
    EntitySelect,
    Loader,
  },
})
export default class ActivityTypeForm extends Vue {
  private enumForm = EnumForm;

  @Prop()
  public id!: string;

  private provider!: DataProvider<ActivityType>;
  private crud!: ICrudClient<ActivityType>;

  private item: any = null;


  created() {
    this.provider = this.$service.providers.activityTypes;
    this.crud = this.$service.crud.activityTypes;
  }

  mounted() {
    if (this.id === "new") {
      this.item = {
        value: "",
      };
    }
  }
}
